import React from "react"

import Layout from "../components/allstar/layout"
import SectionHero from "../components/allstar/faq/section-hero"
import SectionBody from "../components/allstar/faq/section-body"

const FAQPage = props => {
  return (
    <Layout location={props.location} title={"Frequently Asked Questions (FAQ)"}>
      <SectionHero />
      <SectionBody />
    </Layout>
  )
}

export default FAQPage
